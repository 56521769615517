<template>
	<div class="index" style="display: flex;width: 1200px;margin: 0 auto;margin-top: 20px;flex-wrap: wrap;">
		<workBox :state="'exper'" class="animate__animated animate__fadeInLeft" :pagination="false" :rollHeight="230"
			:columns="column" :data="data" :title="'我的实验记录'" style="width: 690px;height: 372px;" :loading="experimentLoading"></workBox>
		<workBox :state="'exper-audit'" class="animate__animated animate__fadeInRight" :pagination="false" :rollHeight="185"
			:columns="column5" :data="data5" :title="'实验归档审核'" :msgCount="data5MsgCount" style="width: 486px;height: 332px;margin-left: 24px;" :loading="experAutditLoading">
		</workBox>
		<workBox :state="'item'" class="animate__animated animate__bounceInUp" :pagination="false" :rollHeight="230"
			:columns="column3" :data="data3" :title="'项目管理'" style="width: 690px;height: 372px;margin: 24px 0;" :loading="projectLoading">
		</workBox>
		<workBox :state="'notice'" class="animate__animated animate__fadeInRight" :pagination="false" :rollHeight="185"
			:columns="column2" :data="data2" :title="'系统公告'" style="width: 486px;height: 332px;margin-left: 24px;" :loading="notificationLoading">
		</workBox>
	<!-- 	<workBox :state="'cage'" class="animate__animated animate__bounceInRight" :pagination="false" :rollHeight="185"
			:columns="column4" :data="data4" :title="'笼位预约记录'"
			style="width: 486px;height: 332px;margin-left: 24px;margin-top: -16px;"></workBox> -->
	</div>
</template>

<script>
import {
	getmyworkcreateprogect,
	querynoticepageworklist,
	getMyExperimentList,
	getUserInfo,
	getWorkbenchMyAuditExperimentPageList
} from '../../api/anotherIndex'
import workBox from '../../components/workBox.vue'
import store from '../../store'
// columns列值 data表格数据 rollHeight表格滚动高度 pagination分页配置项
export default {

	components: {
		workBox
	},
	data() {
		return {
			column: [{
				title: '实验名称',
				dataIndex: 'title',
				key: 'title',
				scopedSlots: {
					customRender: 'experimentTitle'
				},
			},
			{
				title: '最近修改时间',
				dataIndex: 'updatedTime',
				key: 'updatedTime',
			}
			],
			column2: [{
				title: '公告标题',
				dataIndex: 'titleName',
				key: 'titleName',
				scopedSlots: {
					customRender: 'titleName'
				},
			},
			{
				title: '发布时间',
				dataIndex: 'publicTime',
				key: 'publicTime',
			}
			],
			column3: [{
				title: '项目名称',
				dataIndex: 'name',
				key: 'name',
				scopedSlots: {
					customRender: 'projectName'
				},

			},
			{
				title: '项目编号',
				dataIndex: 'code',
				key: 'code',


			},
			{
				title: '项目归属',
				dataIndex: 'isCreate',
				key: 'isCreate',
				scopedSlots: {
					customRender: 'isCreate'
				},
			},
			{
				title: '最近修改时间',
				dataIndex: 'updatedTime',
				key: 'updatedTime',
			},
			],
			/* column4: [{
				title: '动物笼',
				dataIndex: 'name',
				key: 'name',
				width: '150px'
			},
			{
				title: '申请时间',
				dataIndex: 'startTime',
				key: 'startTime',
				width: '180px',
				scopedSlots: {
					customRender: 'appTime'
				},

			},
			{
				title: '状态',
				dataIndex: 'bookingStatus',
				key: 'bookingStatus',
				scopedSlots: {
					customRender: 'bookingStatus'
				},
			},
			], */
			column5: [
				{
					title: '实验名称',
					key: 'title',
					dataIndex: 'title',
					scopedSlots: {
						customRender: 'auditExperimentTitle'
					},
				},
				{
					title: '申请时间',
					key: 'updatedTime',
					dataIndex: 'updatedTime'
				},
				{
					title: '实验状态',
					key: 'auditStatus',
					scopedSlots: {
						customRender: 'auditStatus'
					}
				}
			],
			data: [],
			data2: [],
			data3: [],
			data4: [],
			data5: [],
			data5MsgCount: 0,
			experimentLoading: false,
			notificationLoading: false,
			projectLoading: false,
			experAutditLoading: false,
		}
	},

	async created() {
		//this.getUserInfo('reflash')
		if (!window.localStorage.getItem('Authorization'))
			return;
		/* let data4 = "?num=6"
		const res = await this.getbookingworkhistory(data4); */
		

		//let data3 = "?num=6"
		this.getmyworkcreateprogect()
		//let data2 = "?num=6"
		this.querynoticepageworklist()
		//let data = "?num=6"
		this.getMyExperimentList()
		this.getAuditExperimentList();
		const token = localStorage.getItem('Authorization');
			if (token) {
				store.dispatch('sysNotice/getNotice');
			}
	},
	methods: {
		async getUserInfo(e) {
			const res = await getUserInfo()
			if (res.success) {
				this.$store.commit('setUserInfo', res.data)
			}
		},
		// 获取项目列表
		async getmyworkcreateprogect() {
			this.projectLoading = true;
			const res = await getmyworkcreateprogect({
				num: 6
			})
			if (res.success) {
				this.data3 = res.data
				this.projectLoading = false;
			}
		},
		// 获取通知公告列表
		async querynoticepageworklist() {
			this.notificationLoading = true;
			const res = await querynoticepageworklist({
				num: 6
			})
			if (res.success) {
				this.data2 = res.data
				this.notificationLoading = false;
			}
		},
		// 获取实验列表
		async getMyExperimentList() {
			this.experimentLoading = true;
			const res = await getMyExperimentList({
				num: 6
			})
			if (res.success) {
				this.data = res.data
				this.experimentLoading = false;
			}
		},
		// 获取待审核实验列表
		async getAuditExperimentList() {
			this.experAutditLoading = true;
			const res = await getWorkbenchMyAuditExperimentPageList();
			if(res.success) {
				this.data5 = res.data.myAduitExperiments;
				this.data5MsgCount = res.data.total;
				this.experAutditLoading = false;
			} 
		}

	}
}
</script>

<style scoped>
</style>
