<template>
	<div class="workBox">
		<div class="header" @click="goToDetail">
			<div style="display: flex;align-items: center;">
				<img v-if="state=='exper'" src="../assets/test-tube.png" style="width: 16px;" />
				<img v-if="state=='notice'" src="../assets/megaphone.png" style="width: 16px;" />
				<img v-if="state=='item'" src="../assets/layers.png" style="width: 16px;" />
				<img v-if="state=='cage'" src="../assets/calendar.png" style="width: 16px;" />
				<img v-if="state=='exper-audit'" src="../assets/exper-audit.png" style="width: 16px;" />
				
				<div
					style="font-weight: 550; font-size: 18px;color: rgba(0, 0, 0, 0.85);line-height: 0;margin-left: 10px; display: flex; align-items: center;">
					<span>{{title}}</span>  <a-badge style="margin-left: 8px;" :count="msgCount"></a-badge>
				</div>
			</div>
			<div>
				<span style="color: #4E5969;"> 查看 </span>
				<a-icon style="color: #4E5969;" type="right" />
			</div>
			
		</div>
		<div class="content">
			<sTable  :pagination="pagination" :rollHeight="rollHeight" :columns="columns" :data="data"
				:data-source="data" :loading="loading">
				<template slot="experimentTitle" slot-scope="data">
					<a title="打开" class="link" :href="getExperimentHref(data.record.id, data.record.projectId)" target="_blank"> {{ data.record.title }} </a>
				</template>
				<template slot="auditExperimentTitle" slot-scope="data">
					<a :title="data.record.title" class="link" :href="getExperimentHref(data.record.experimentId, data.record.projectId)" target="_blank"> {{ data.record.title }} </a>
				</template>
				<template slot="titleName" slot-scope="data">
					<a title="打开" class="link" :href="getSysNoticeHref(data.record.noticeId)" target="_blank"> {{ data.record.titleName }} </a>
				</template>
				<template slot="projectName" slot-scope="data">
					<a title="打开" class="link" :href="getProjectHref(data.record.projectId)" target="_blank"> {{ data.record.name }} </a>
				</template>
				
				<span slot="bookingStatus" slot-scope="data">
					<!-- <a-tag
						:color="data.slotProps==1?'orange':data.slotProps==2?'green':data.slotProps==3?'red':data.slotProps==4?'gray':data.slotProps==5?'':''">
						{{data.slotProps==1?'申请中':data.slotProps==2?'通过':data.slotProps==3?'不通过':
						data.slotProps==4?'时间到期':data.slotProps==5?'取消':'其他'
						}} -->
					<a-tag
						:color="cageAppointStatuColorMap[data.slotProps]">
						 {{ cageAppointStatuMap.value[data.slotProps] }} 
					</a-tag>
				</span>
				<span slot="appTime" slot-scope="data">
					<div style="display: flex;align-items: center;">
						<a-badge color="green" status="processing" />
						{{data.record.startTime}}
					</div>
					<div style="display: flex;align-items: center;margin-top: 0.3125rem;">
						<a-badge color="red" status="processing" />
						 {{data.record.endTime}}
						<!-- .split(' ')[0] -->
					</div>
					 
				</span>
				<span slot="isCreate" slot-scope="isCreate">
					{{isCreate.record.isHead==1?'我创建的':'我参与的'}}
				</span>
				<span slot="auditStatus" slot-scope="data" style="color:#FAAD14;">
					待审核
				</span>
			</sTable>
		</div>
	</div>
</template>

<script>
	import sTable from './sTable.vue'
	import { cageAppointStatuMap, cageAppointStatuColorMap } from '@/utils/enums.js'
	export default {
		props: ['columns', 'data', 'rollHeight', 'pagination', 'title','state', 'loading', 'msgCount'],
		// columns列值 data表格数据 rollHeight表格滚动高度 pagination分页配置项
		components: {
			sTable
		},
		data(){
			return {
				cageAppointStatuMap,
				cageAppointStatuColorMap
			}
		},
		methods:{
			goToDetail(){
				if(this.state=='notice'){
					this.$router.push({path:'/systemNotice'})
				}
				/* if(this.state=='cage'){
					this.$router.push({path:'/core/cageLog'})
				} */
				if(this.state == 'cageRecord') {
					this.$router.push({
						name: 'peopleCenterCageLog'
					})
				}
				if(this.state=='exper'){
					this.$router.push({path:'/experManage'})
				}
				if(this.state=='item'){
					this.$router.push({path:'/itemCenter/index'})
				}
				if(this.state == 'exper-audit') {
					this.$router.push({name: 'experimentAuditList'})
				}
				
			},
			// 获取实验href
			getExperimentHref(id, projectId) {
				const href = this.$router.resolve({
					name: 'experiment',
					query: {
						id: id,
						projectId: projectId
					}
				});
				return href.href;
			},
			// 获取系统公告href
			getSysNoticeHref(id) {
				const href = this.$router.resolve({
					name: 'systemNoticeDetail',
					query: {
						id: id
					}
				});
				return href.href
			},
			// 获取项目中心href
			getProjectHref(projectId) {
				const href = this.$router.resolve({
					name: 'item',
					query: {
						projectId: projectId
					}
				});
				return href.href
			}
		}
	}
</script>

<style scoped>
	.header {
		border-bottom: 1px solid #EBEBEB;
		display: flex;
		justify-content: space-between;
		margin: 0 25px;
		margin-top: 5px;
		padding: 20px 0px;
		cursor: pointer;
	}
	.header:hover{
		opacity: 0.8;
	}

	.workBox {
		border-radius: 8px;
		background: #FFFFFF;
	}

	.content {
		margin: 0 22px;
		margin-top: 15px;

	}
	.link {
		text-decoration: underline;
		color: rgba(0, 0, 0, 0.65);
	}
</style>

<style lang="less" scoped>
.workBox {
	box-shadow: @srims-primary-box-shadow-light;
}
</style>